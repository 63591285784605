import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { useState } from 'react';
import { InputDialog } from 'wix-ui-tpa';
import {
  CartFlow,
  CartModalStatus,
  ReservedPaymentOptionIds,
  ServicePaymentDetails,
} from '../../../../types/types';
import { useFormActions } from '../../Hooks/useFormActions';
import { Service } from '../../../../utils/mappers/service.mapper';
import { Slot } from '@wix/ambassador-availability-calendar/types';
import { CartModalDataHooks } from './dataHooks';
import { Membership } from '@wix/ambassador-memberships-spi-host/http';
import { ModalContent } from './ModalContent/ModalContent';
import { ButtonProp, ModalFooter } from './ModalFooter/ModalFooter';
import { classes } from './CartModal.st.css';
import { MobileModal } from './MobileModal/MobileModal';

export interface CartModalProps {
  cartModalStatus: CartModalStatus;
  service: Service;
  slot: Slot;
  dateRegionalSettingsLocale: string;
  selectedPaymentOptionId: string;
  cartLineItems: any[];
  eligibleMemberships?: Membership[];
  isMobile: boolean;
  isRTL: boolean;
  paymentDetails?: ServicePaymentDetails;
}

const CartModal: React.FC<CartModalProps> = ({
  cartModalStatus,
  service,
  dateRegionalSettingsLocale,
  slot,
  selectedPaymentOptionId,
  cartLineItems,
  eligibleMemberships,
  isMobile,
  isRTL,
  paymentDetails,
}) => {
  const { t } = useTranslation();
  const { onCartCTAClick } = useFormActions();
  const isFreePrice =
    service?.payment?.paymentDetails?.isFree &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
  const isCustomPrice =
    service?.payment?.paymentDetails?.priceText &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
  const isMembershipOptionSelected = eligibleMemberships?.some(
    (membership) => selectedPaymentOptionId === membership.id,
  );

  const isSingleItemOnCart = cartLineItems?.length === 1;

  const shouldSecondaryButtonBeCheckout =
    isSingleItemOnCart &&
    (isFreePrice ||
      isCustomPrice ||
      isMembershipOptionSelected ||
      service.isPendingApprovalFlow);

  const isOpen = cartModalStatus === CartModalStatus.OPEN;
  const onClose = () => onCartCTAClick(CartFlow.ADD_MORE_SESSIONS);

  const title = t('app.booking-details.cart-modal.title');

  const [primaryButtonLoading, setPrimaryButtonLoading] = useState<boolean>(
    false,
  );
  const [
    secondaryaryButtonLoading,
    setSecondaryaryButtonLoading,
  ] = useState<boolean>(false);

  const primaryButton: ButtonProp = {
    dataHook: CartModalDataHooks.PRIMARY_BUTTON,
    text: t('app.booking-details.cart-modal.cta.add-more-sessions.label'),
    onClick: async () => {
      setPrimaryButtonLoading(true);
      await onCartCTAClick(CartFlow.ADD_MORE_SESSIONS);
    },
    loading: primaryButtonLoading,
  };
  const secondaryButton: ButtonProp = {
    dataHook: CartModalDataHooks.SECONDARY_BUTTON,
    text: t(
      shouldSecondaryButtonBeCheckout
        ? 'app.booking-details.cart-modal.cta.complete-checkout.label'
        : 'app.booking-details.cart-modal.cta.checkout.label',
    ),
    onClick: async () => {
      setSecondaryaryButtonLoading(true);
      await onCartCTAClick(CartFlow.CHECKOUT);
    },
    loading: secondaryaryButtonLoading,
  };

  if (isMobile) {
    return (
      <MobileModal
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        service={service}
        slot={slot}
        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        isRTL={isRTL}
        dataHook={CartModalDataHooks.MOBILE_MODAL}
        paymentDetails={paymentDetails}
      />
    );
  }

  return (
    <InputDialog
      className={classes.root}
      data-hook={CartModalDataHooks.INPUT_DIALOG}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      customFooter={
        <ModalFooter
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      }
    >
      <div className={classes.contentWrapper}>
        <ModalContent
          slot={slot}
          service={service}
          dateRegionalSettingsLocale={dateRegionalSettingsLocale}
          paymentDetails={paymentDetails}
        />
      </div>
    </InputDialog>
  );
};

export default CartModal;
